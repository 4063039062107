// Importing all resources
import "../home.css";
import "../responsive.css";
import hero from "../assets/hero.jpg";
import circles from "../assets/cirlces.png";
import montessoriImg from "../assets/montessori.jpg";
import kindergartenImg from "../assets/kindergarten.jpg";
import nurseryImg from "../assets/nursery.jpg";
import primaryImg from "../assets/primary.jpg";
import newsOne from "../assets/news1.png";
import newsTwo from "../assets/news2.png";
import newsThree from "../assets/news3.png";
import momentFourImg from "../assets/momentFour.jpg";
import momentFiveImg from "../assets/momentFive.jpg";
import momentSixImg from "../assets/momentSix.jpg";
import momentSevenImg from "../assets/momentSeven.jpg";
import emailImg from "../assets/emailIcon.png";
import locationImg from "../assets/location-marker.png";
import phoneImg from "../assets/phone.png";
import mobileIntroHero from "../assets/homeMobileHero.webp";
import mobileMissionImg from "../assets/missionMobile.png";
import avatarOne from "../assets/Avatar.png";
import avatarTwo from "../assets/Avatar-1.png";
import avatarThree from "../assets/Avatar-2.png";
import Header from "../components/header";
import Footer from "../components/footer";
import Badge from "../components/badge";
import Button from "../components/button";
import Navbartrial from "../components/navbartrial";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// First section
const IntroPage = () => {
  return (
    <article className="intro">
      <Header
        title="Where Education Meets Innovation"
        text="Nurturing Minds, Inspiring Futures, and forseeing a Passion for
          Lifelong Learning"
        btnText="Explore Our Programs"
        linkUrl="#programs"
      />
      <article className="heroArticle toBeHidden">
        <img src={hero} alt="Three students" />
        <img src={circles} alt="Three students" className="circles" />
      </article>
      <img
        src={mobileIntroHero}
        alt="A little girl painting"
        className="hide mobileImg"
      />
    </article>
  );
};

// Complete card component
const CompleteCard = (props) => {
  const cardsArray = [
    {
      heading: "Creche",
      text: `Explore our Creche program, where independence, exploration, and hands-on learning take centerstage...`,
      imageSrc: montessoriImg,
      imageAltText: "A small boy starstruck by his toys",
      badgeOne: "Independence",
      badgeTwo: "Exploration",
      badgeThree: "Learning",
      badgeFour: "Development",
      url: "/creche",
    },
    {
      heading: "Pre-School",
      text: `Experience our dynamic Pre-School program, where social-emotional development, critical thinking, and cre...`,
      imageSrc: kindergartenImg,
      imageAltText: "A lady reading three babies a story book",
      badgeOne: "Critical Thinking",
      badgeTwo: "Creativity",
      badgeThree: "Lifelong Skills",
      badgeFour: "Development",
      url: "/preschool",
    },
    {
      heading: "Nursery",
      text: `Discover our nurturing Nursery program, providing a safe and engaging environment for your child's early edu...`,
      imageSrc: nurseryImg,
      imageAltText:
        "A mom and her son drawing on a piece of paper with crayons",
      badgeOne: "Motor Skills",
      badgeTwo: "Social Interaction",
      badgeThree: "Play-Based Learning",
      badgeFour: "Development",
      url: "/nursery",
    },
    {
      heading: "Primary",
      text: `Unlock your child's potential in our comprehensive Primary program, fostering academic excellence, critical...`,
      imageSrc: primaryImg,
      imageAltText: "A little girl writing on a piece of paper with a pen",
      badgeOne: "Critical Thinking",
      badgeTwo: "Problem-Solving",
      badgeThree: "Academic Excellence",
      badgeFour: "Development",
      url: "/primary",
    },
  ];
  const targetObject = cardsArray.find((item) => item.heading === props.value);
  return (
    <>
      <Link to={targetObject.url}>

        <article className="card">
          <img src={targetObject.imageSrc} alt={targetObject.imageAltText} />
          <article className="content">
            <h3>{targetObject.heading}</h3>
            <p>{targetObject.text}</p>
            <Badge value={targetObject.badgeOne} />
            <Badge value={targetObject.badgeTwo} />
            <Badge value={targetObject.badgeThree} />
            <Badge value={targetObject.badgeFour} />
            <br />
            {/* <a href={targetObject.url}>Read More...</a> */}
          </article>
        </article>
      </Link>

    </>
  );
};

// Second Section / Program Section
const Programs = ({ screenSize }) => {
  useEffect(() => {
    const carousel = document.querySelector(".carousel"),
      firstImg = carousel.querySelectorAll("img")[1],
      arrowIcons = document.querySelectorAll(".wrapper i");

    let isDragStart = false,
      isDragging = false,
      prevPageX,
      prevScrollLeft,
      positionDiff;
    arrowIcons[0].style.display = "block";
    const showHideIcons = () => {
      // showing and hiding prev/next icon according to carousel scroll left value
      let scrollWidth = carousel.scrollWidth - carousel.clientWidth; // getting max scrollable width
      // arrowIcons[0].style.display = carousel.scrollLeft === 0 ? "none" : "block";
      // arrowIcons[1].style.display =
      //   carousel.scrollLeft === scrollWidth ? "none" : "block";
      if (carousel.clientWidth <= 550) {
        arrowIcons[0].style.display =
          carousel.scrollLeft === 0 ? "none" : "none";
        arrowIcons[1].style.display =
          carousel.scrollLeft === scrollWidth ? "none" : "none";
      }
    };

    arrowIcons.forEach((icon) => {
      icon.addEventListener("click", () => {
        let firstImgWidth = firstImg.clientWidth; // getting first img width & adding 14 margin value
        // if clicked icon is left, reduce width value from the carousel scroll left else add to it
        carousel.scrollLeft +=
          icon.id === "left" ? -firstImgWidth : firstImgWidth;
        setTimeout(() => showHideIcons(), 60); // calling showHideIcons after 60ms
      });
    });

    const autoSlide = () => {
      // if there is no image left to scroll then return from here
      if (
        carousel.scrollLeft - (carousel.scrollWidth - carousel.clientWidth) >
        -1 ||
        carousel.scrollLeft <= 0
      )
        return;

      positionDiff = Math.abs(positionDiff); // making positionDiff value to positive
      let firstImgWidth = firstImg.clientWidth;
      // getting difference value that needs to add or reduce from carousel left to take middle img center
      let valDifference = firstImgWidth - positionDiff;

      if (carousel.scrollLeft > prevScrollLeft) {
        // if user is scrolling to the right
        return (carousel.scrollLeft +=
          positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff);
      }
      // if user is scrolling to the left
      carousel.scrollLeft -=
        positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff;
    };

    const dragStart = (e) => {
      // updatating global variables value on mouse down event
      isDragStart = true;
      prevPageX = e.pageX || e.touches[0].pageX;
      prevScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e) => {
      // scrolling images/carousel to left according to mouse pointer
      if (!isDragStart) return;
      e.preventDefault();
      isDragging = true;
      carousel.classList.add("dragging");
      positionDiff = (e.pageX || e.touches[0].pageX) - prevPageX;
      carousel.scrollLeft = prevScrollLeft - positionDiff;
      showHideIcons();
    };

    const dragStop = () => {
      isDragStart = false;
      carousel.classList.remove("dragging");

      if (!isDragging) return;
      isDragging = false;
      autoSlide();
    };
    if (carousel.clientWidth <= 550) {
      const rightButton = document.querySelector(".wrapper i#right");
      rightButton.click();
      rightButton.click();
      rightButton.click();
    }
    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("touchstart", dragStart);

    document.addEventListener("mousemove", dragging);
    carousel.addEventListener("touchmove", dragging);

    document.addEventListener("mouseup", dragStop);
    carousel.addEventListener("touchend", dragStop);
  }, []);


  return (
    <article className="programsWrapper" id="programs">
      <div className="heading">
        <h2>Programs & Curriculum</h2>
        <p>Nurturing Growth and Empowering Learners for a Bright Future</p>
      </div>
      <article className="wrapper">
        <i id="left" className="fa-solid fa-angle-left toBeHidden"></i>
        <div className={`${screenSize > 1000 ? 'carousel' : 'carousell'}`}>
          <CompleteCard value="Creche" />
          <CompleteCard value="Pre-School" />
          <CompleteCard value="Nursery" />
          <CompleteCard value="Primary" />
          <i id="right" className="fa-solid fa-angle-right toBeHidden"></i>
        </div>
      </article>
    </article>
  );
};

// Basic Card component
const BasicCard = (props) => {
  const cardsArray = [
    {
      heading: "Annual Science Fair",
      text: `Join us for our highly anticipated Annual Science Fair, where pupils showcase their crea...`,
      imageSrc: newsOne,
      imageAltText: "A rocket flying around what is supposed to be our planet",
      badgeOne: "Science Fair",
      badgeTwo: "Innovation",
      badgeThree: "Discovery",
      date: "March 20, 2015",
    },
    {
      heading: "Community Outreach",
      text: `Our school community actively participates in community outreach initiatives, making a posit...`,
      imageSrc: newsTwo,
      imageAltText: "People putting their hands together in agreement",
      badgeOne: "Volunteering",
      badgeTwo: "Social Responsibility",
      badgeThree: "Community Outreach",
      date: "July 20, 2015",
    },
    {
      heading: "Author Visit",
      text: `We are thrilled to host a renowned children's author for an interactive session. Students will...`,
      imageSrc: newsThree,
      imageAltText: "People in an art museum staring at a piece of heart",
      badgeOne: "Author Visit",
      badgeTwo: "Literature",
      badgeThree: "Discovery",
      date: "March 10, 2015",
    },
  ];
  const targetObject = cardsArray.find((item) => item.heading === props.value);
  return (
    <>
      <article className="card basic">
        <img src={targetObject.imageSrc} alt={targetObject.imageAltText} />
        <article className="content">
          <p>{targetObject.date}</p>
          <h3>{targetObject.heading}</h3>
          <p>{targetObject.text}</p>
          <Badge value={targetObject.badgeOne} />
          <Badge value={targetObject.badgeTwo} />
          <Badge value={targetObject.badgeThree} />
        </article>
      </article>
    </>
  );
};

// Third Section / News Section
const News = ({ screenSizee }) => {
  useEffect(() => {
    const carousel = document.querySelector(".newsWrapper .carousel"),
      firstImg = carousel.querySelectorAll("img")[0],
      arrowIcons = document.querySelectorAll(".newsWrapper i");

    let isDragStart = false,
      isDragging = false,
      prevPageX,
      prevScrollLeft,
      positionDiff;
    if (carousel.clientWidth > 550) {
      arrowIcons[0].style.display = "block";
    }
    const showHideIcons = () => {
      // showing and hiding prev/next icon according to carousel scroll left value
      let scrollWidth = carousel.scrollWidth - carousel.clientWidth; // getting max scrollable width
      // arrowIcons[0].style.display = carousel.scrollLeft === 0 ? "none" : "block";
      // arrowIcons[1].style.display =
      //   carousel.scrollLeft === scrollWidth ? "none" : "block";
      if (carousel.clientWidth <= 550) {
        arrowIcons[0].style.display =
          carousel.scrollLeft === 0 ? "none" : "none";
        arrowIcons[1].style.display =
          carousel.scrollLeft === scrollWidth ? "none" : "none";
      }
    };

    arrowIcons.forEach((icon) => {
      icon.addEventListener("click", () => {
        let firstImgWidth = firstImg.clientWidth; // getting first img width & adding 14 margin value
        // if clicked icon is left, reduce width value from the carousel scroll left else add to it
        carousel.scrollLeft +=
          icon.id === "left" ? -firstImgWidth : firstImgWidth;
        setTimeout(() => showHideIcons(), 60); // calling showHideIcons after 60ms
      });
    });

    const autoSlide = () => {
      // if there is no image left to scroll then return from here
      if (
        carousel.scrollLeft - (carousel.scrollWidth - carousel.clientWidth) >
        -1 ||
        carousel.scrollLeft <= 0
      )
        return;

      positionDiff = Math.abs(positionDiff); // making positionDiff value to positive
      let firstImgWidth = firstImg.clientWidth;
      // getting difference value that needs to add or reduce from carousel left to take middle img center
      let valDifference = firstImgWidth - positionDiff;

      if (carousel.scrollLeft > prevScrollLeft) {
        // if user is scrolling to the right
        return (carousel.scrollLeft +=
          positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff);
      }
      // if user is scrolling to the left
      carousel.scrollLeft -=
        positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff;
    };

    const dragStart = (e) => {
      // updatating global variables value on mouse down event
      isDragStart = true;
      prevPageX = e.pageX || e.touches[0].pageX;
      prevScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e) => {
      // scrolling images/carousel to left according to mouse pointer
      if (!isDragStart) return;
      e.preventDefault();
      isDragging = true;
      carousel.classList.add("dragging");
      positionDiff = (e.pageX || e.touches[0].pageX) - prevPageX;
      carousel.scrollLeft = prevScrollLeft - positionDiff;
      showHideIcons();
    };

    const dragStop = () => {
      isDragStart = false;
      carousel.classList.remove("dragging");

      if (!isDragging) return;
      isDragging = false;
      autoSlide();
    };

    if (carousel.clientWidth <= 550) {
      const rightButton = document.querySelector(".wrapper i#right");
      rightButton.click();
      rightButton.click();
      rightButton.click();
    }
    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("touchstart", dragStart);

    document.addEventListener("mousemove", dragging);
    carousel.addEventListener("touchmove", dragging);

    document.addEventListener("mouseup", dragStop);
    carousel.addEventListener("touchend", dragStop);
  }, []);

  return (
    <>
      <article className="newsWrapper">
        <div className="heading">
          <h2>News & Events</h2>
          <p>Discover the Vibrant Happenings in Our School Community</p>
        </div>
        <article className="wrapper">
          <i id="left" className="fa-solid fa-angle-left toBeHidden"></i>
          <div className={`${screenSizee > 1000 ? 'carousel' : 'carousell'} carousel sui`}>
            <BasicCard value="Annual Science Fair" />
            <BasicCard value="Community Outreach" />
            <BasicCard value="Author Visit" />
            <BasicCard value="Annual Science Fair" />
            <BasicCard value="Community Outreach" />
            <BasicCard value="Author Visit" />
            <i id="right" className="fa-solid fa-angle-right toBeHidden"></i>
          </div>
          <div className={`${screenSizee > 1000 ? 'carousel' : 'carousell'} carousell sss`}>
            <BasicCard value="Annual Science Fair" />
            <BasicCard value="Community Outreach" />
            <BasicCard value="Author Visit" />
            <BasicCard value="Annual Science Fair" />
            <BasicCard value="Community Outreach" />
            <BasicCard value="Author Visit" />
            <i id="right" className="fa-solid fa-angle-right toBeHidden"></i>
          </div>
        </article>
      </article>
    </>
  );
};

// Fourth Section / Inquiry Section
const InquirySection = () => {
  return (
    <>
      <article className="inquirySection">
        <article className="content">
          <h3>Your Child's Future Starts Here</h3>
          <p>Where Education Meets Inspiration and Endless Possibilities</p>
          <a href="/admission">
            <button className="pinkBtn">Inquire Today</button>
          </a>
        </article>
      </article>
    </>
  );
};

// Fifth Section / Mission Section
const MissionSection = () => {
  return (
    <>
      <article className="missionSection">
        <article className="contentOne">
          <h2>Our Mission and Core Values</h2>
          <p>
            To continually provide quality education that is relevant and meaningful to the upbringing and training of our pupils relying on cutting-edge technology, dedicated and qualified personnel to ensure that our graduate can hold on their leaders in different endeavours and can excel in the world stage.
          </p>
          <p>
            <ul>
              <li>Integrity</li>
              <li>Commitment</li>
              <li>Honesty</li>
              <li>Excellence</li>
              <li>Accountability</li>
              <li>Respect to Diversity</li>
              <li>Service to God and Humanity</li>
            </ul>
          </p>
          <a href="/about">
            <Button value="Learn More" />
          </a>
        </article>
        <article className="contentFive toBeHidden">
          <img src={momentFourImg} alt="" />
        </article>
        <article className="contentSix toBeHidden">
          <img src={momentFiveImg} alt="" />
        </article>
        <article className="contentSeven toBeHidden">
          <img src={momentSixImg} alt="" />
        </article>
        <article className="contentEight toBeHidden">
          <img src={momentSevenImg} alt="" />
        </article>
      </article>
      <article className="hide missionArticle">
        <img
          src={mobileMissionImg}
          alt="Few children and two adults sitting in a circle playing"
        />
      </article>
    </>
  );
};

// Sixth Section / Sign Up Section
const SignUpSection = () => {
  return (
    <>
      <article className="signupSection">
        <article className="content">
          <h3>Stay Updated with Our School's Newsletter</h3>
          <p>
            Get the Latest Updates, News, and Insights Straight to Your Inbox
          </p>
          <label htmlFor="">
            <input type="email" placeholder="Email" />
            <button className="pinkBtn">Subscribe Now</button>
          </label>
        </article>
      </article>
    </>
  );
};

// Seventh Section / Testimonial Section
const TestimonialSection = ({ screenSize }) => {
  useEffect(() => {
    const carousel = document.querySelector(".testimonialsWrapper .carousel"),
      firstImg = carousel.querySelectorAll(".content")[0],
      arrowIcons = document.querySelectorAll(".testimonialsWrapper i");

    let isDragStart = false,
      isDragging = false,
      prevPageX,
      prevScrollLeft,
      positionDiff;

    const showHideIcons = () => {
      // showing and hiding prev/next icon according to carousel scroll left value
      let scrollWidth = carousel.scrollWidth - carousel.clientWidth; // getting max scrollable width
      arrowIcons[0].style.display = carousel.scrollLeft === 0 ? "none" : "block";
      arrowIcons[1].style.display =
        carousel.scrollLeft === scrollWidth ? "none" : "block";
      if (carousel.clientWidth <= 550) {
        arrowIcons[0].style.display =
          carousel.scrollLeft === 0 ? "none" : "none";
        arrowIcons[1].style.display =
          carousel.scrollLeft === scrollWidth ? "none" : "none";
      }
    };

    arrowIcons.forEach((icon) => {
      icon.addEventListener("click", () => {
        let firstImgWidth = firstImg.clientWidth + 14; // getting first img width & adding 14 margin value
        // if clicked icon is left, reduce width value from the carousel scroll left else add to it
        carousel.scrollLeft +=
          icon.id === "left" ? -firstImgWidth : firstImgWidth;
        setTimeout(() => showHideIcons(), 60); // calling showHideIcons after 60ms
      });
    });

    const autoSlide = () => {
      // if there is no image left to scroll then return from here
      if (
        carousel.scrollLeft - (carousel.scrollWidth - carousel.clientWidth) >
        -1 ||
        carousel.scrollLeft <= 0
      )
        return;

      positionDiff = Math.abs(positionDiff); // making positionDiff value to positive
      let firstImgWidth = firstImg.clientWidth + 14;
      // getting difference value that needs to add or reduce from carousel left to take middle img center
      let valDifference = firstImgWidth - positionDiff;

      if (carousel.scrollLeft > prevScrollLeft) {
        // if user is scrolling to the right
        return (carousel.scrollLeft +=
          positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff);
      }
      // if user is scrolling to the left
      carousel.scrollLeft -=
        positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff;
    };

    const dragStart = (e) => {
      // updatating global variables value on mouse down event
      isDragStart = true;
      prevPageX = e.pageX || e.touches[0].pageX;
      prevScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e) => {
      // scrolling images/carousel to left according to mouse pointer
      if (!isDragStart) return;
      e.preventDefault();
      isDragging = true;
      carousel.classList.add("dragging");
      positionDiff = (e.pageX || e.touches[0].pageX) - prevPageX;
      carousel.scrollLeft = prevScrollLeft - positionDiff;
      showHideIcons();
    };

    const dragStop = () => {
      isDragStart = false;
      carousel.classList.remove("dragging");

      if (!isDragging) return;
      isDragging = false;
      autoSlide();
    };
    if (carousel.clientWidth <= 550) {
      const rightButton = document.querySelector(".wrapper i#right");
      console.log(rightButton);
    }
    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("touchstart", dragStart);

    document.addEventListener("mousemove", dragging);
    carousel.addEventListener("touchmove", dragging);

    document.addEventListener("mouseup", dragStop);
    carousel.addEventListener("touchend", dragStop);
  }, []);
  return (
    <>
      <article className="testimonialsWrapper">
        <div className="heading">
          <h2>Hear What Our Community Says</h2>
          <p>
            Read testimonials from parents, students, and educators about the
            experience at RPMS
          </p>
        </div>
        <article className="wrapper">
          <i id="left" className="fa-solid fa-angle-left"></i>
          <div className={`${screenSize > 1000 ? 'carousel' : 'carousell'} carousel suivv`}>
            <article className="content">
              <p>
                <cite>
                  "Choosing RPMS for our child was a game-changer! The caring
                  educators and engaging curriculum have sparked a love for
                  learning in our little one. We couldn't be happier with our
                  decision."
                </cite>
                Paula Lopez
                <br />
                <span>Parent</span>
              </p>
              <article className="avatars">
                <img src={avatarOne} alt="" />
                <img src={avatarTwo} alt="" />
                <img src={avatarThree} alt="" />
              </article>
            </article>
            <article className="content">
              <p>
                <cite>
                  "Choosing RPMS for our child was a game-changer! The caring
                  educators and engaging curriculum have sparked a love for
                  learning in our little one. We couldn't be happier with our
                  decision."
                </cite>
                Paula Lopez
                <br />
                <span>Parent</span>
              </p>
              <article className="avatars">
                <img src={avatarOne} alt="" />
                <img src={avatarTwo} alt="" />
                <img src={avatarThree} alt="" />
              </article>
            </article>
            <article className="content">
              <p>
                <cite>
                  "Choosing RPMS for our child was a game-changer! The caring
                  educators and engaging curriculum have sparked a love for
                  learning in our little one. We couldn't be happier with our
                  decision."
                </cite>
                Paula Lopez
                <br />
                <span>Parent</span>
              </p>
              <article className="avatars">
                <img src={avatarOne} alt="" />
                <img src={avatarTwo} alt="" />
                <img src={avatarThree} alt="" />
              </article>
            </article>
            <article className="content">
              <p>
                <cite>
                  "Choosing RPMS for our child was a game-changer! The caring
                  educators and engaging curriculum have sparked a love for
                  learning in our little one. We couldn't be happier with our
                  decision."
                </cite>
                Paula Lopez
                <br />
                <span>Parent</span>
              </p>
              <article className="avatars">
                <img src={avatarOne} alt="" />
                <img src={avatarTwo} alt="" />
                <img src={avatarThree} alt="" />
              </article>
            </article>
            <i id="right" className="fa-solid fa-angle-right toBeHidden"></i>
          </div>

          <div className={`${screenSize > 1000 ? 'carousel' : 'carousell'} carousell sssvv`}>
            <article className="content">
              <p>
                <cite>
                  "Choosing RPMS for our child was a game-changer! The caring
                  educators and engaging curriculum have sparked a love for
                  learning in our little one. We couldn't be happier with our
                  decision."
                </cite>
                Paula Lopez
                <br />
                <span>Parent</span>
              </p>
              <article className="avatars">
                <img src={avatarOne} alt="" />
                <img src={avatarTwo} alt="" />
                <img src={avatarThree} alt="" />
              </article>
            </article>
            <article className="content">
              <p>
                <cite>
                  "Choosing RPMS for our child was a game-changer! The caring
                  educators and engaging curriculum have sparked a love for
                  learning in our little one. We couldn't be happier with our
                  decision."
                </cite>
                Paula Lopez
                <br />
                <span>Parent</span>
              </p>
              <article className="avatars">
                <img src={avatarOne} alt="" />
                <img src={avatarTwo} alt="" />
                <img src={avatarThree} alt="" />
              </article>
            </article>
            <article className="content">
              <p>
                <cite>
                  "Choosing RPMS for our child was a game-changer! The caring
                  educators and engaging curriculum have sparked a love for
                  learning in our little one. We couldn't be happier with our
                  decision."
                </cite>
                Paula Lopez
                <br />
                <span>Parent</span>
              </p>
              <article className="avatars">
                <img src={avatarOne} alt="" />
                <img src={avatarTwo} alt="" />
                <img src={avatarThree} alt="" />
              </article>
            </article>
            <article className="content">
              <p>
                <cite>
                  "Choosing RPMS for our child was a game-changer! The caring
                  educators and engaging curriculum have sparked a love for
                  learning in our little one. We couldn't be happier with our
                  decision."
                </cite>
                Paula Lopez
                <br />
                <span>Parent</span>
              </p>
              <article className="avatars">
                <img src={avatarOne} alt="" />
                <img src={avatarTwo} alt="" />
                <img src={avatarThree} alt="" />
              </article>
            </article>
            <i id="right" className="fa-solid fa-angle-right toBeHidden"></i>
          </div>
        </article>
      </article>
    </>
  );
};

// Eighth Section / Contact Section
const ContactSection = () => {
  return (
    <>
      <article className="contactSection">
        <div className="mapImg"></div>
        <article className="emptyDiv toBeHidden"></article>
        <article className="contactDiv">
          <article className="contactDetails toBeHidden">
            <article className="details phone">
              <img src={phoneImg} alt="A phone icon" />
              <a href="tel:+2347037140736">
                <article>
                  <h4>Phone Number</h4>
                  <p>+(234) 703 714 0736</p>
                </article>
              </a>
            </article>
            <article className="details mail">
              <a href="googlegmail://">
                <img src={emailImg} alt="A letter" />
                <article>
                  <h4>Email Address</h4>
                  <p >support@rulerspala cemontessori.com.ng</p>
                </article>
              </a>
            </article>
            <article className="details location">
              <a href="geo:37.7749,-122.4194">
                <img src={locationImg} alt="A phone icon" />
                <article>
                  <h4>Location</h4>
                  <p>
                    1 Jesus Is Lord Street Igbogbobayeku, Ikorodu, Lagos,
                    Nigeria
                  </p>
                </article>
              </a>
            </article>
          </article>
          <article className="formDiv">
            <p>Send Message</p>
            <p>Reach Out for Inquiries, Visits and More</p>
            <form action="" method="post">
              <article className="formFirstRow">
                <label>
                  Name
                  <br />
                  <input type="text" placeholder="e.g John" />
                </label>
                <label>
                  Email
                  <br />
                  <input type="email" placeholder="e.g company@gmail.com" />
                </label>
              </article>
              <label>
                Message
                <br />
                <textarea
                  cols="30"
                  rows="10"
                  placeholder={
                    "Thank you for your interest in RPMS. We would be delighted to assist you with any inquiries or provide further information about our school.\nPlease fill out the form below, and we will get back to you as soon as possible. We look forward to hearing from you!"
                  }
                ></textarea>
              </label>
              <Button value="Submit" />
            </form>
          </article>
        </article>
      </article>
    </>
  );
};

export default function Home() {
  // Smooth scrolling function
  const smoothScrollTo = (target) => {
    const element = document.querySelector(target);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    // Add event listeners to the navigation links
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", (e) => {
        e.preventDefault();
        const targetId = anchor.getAttribute("href");
        smoothScrollTo(targetId);
      });
    });
  }, []);

  const [screenSize, setScreenSize] = useState(window.innerWidth)

  useEffect(() => {

    const handleResize = () => setScreenSize(window.innerWidth)
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);

  }, [screenSize])

  return (
    <>
      <Navbartrial />
      <IntroPage />
      <Programs screenSize={screenSize} />
      <InquirySection />
      <MissionSection />
      <News screenSizee={screenSize} />
      <SignUpSection />
      <TestimonialSection screenSizee={screenSize} />
      <ContactSection />
      <Footer />
    </>
  );
}
